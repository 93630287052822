import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import GeneralAnxiety from '../../components/Specialties/components/GeneralAnxiety';

export default () => (
  <React.Fragment>
    <SEO
      title="Anxiety Treatment Center in Provo, Utah | Mindset Family Therapy"
      description="Once you are in treatment for your anxiety, you will learn the correct skills to help you look at your thoughts and feelings with a different set of eyes."
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498617349/anxietyQuote.jpg"
      pathname="/specialties/general-anxiety-disorder"
      article
    />
    <Layout>
      <GeneralAnxiety />
    </Layout>
  </React.Fragment>
);
