const generalAnxiety = {
  quote1:
    '<p>“Challenges are what make life interesting and overcoming them is what makes life meaningful.”</p><p>JOSHUA J. MARINE</p>',
  overview:
    'You may not be sure of the solution, so you spend a lot of time “worrying” and trying to\n' +
    'figure things out. The truth is, everything in the world is uncertain and yes, we may lose\n' +
    'our jobs, a family member may get cancer, or have a fatal accident. There may not be an\n' +
    'end to your worries. If it is not your family, it may be your job, or a natural disaster, but\n' +
    'there is always something to worry about.<br/><br/>If your answer is yes to most of these questions, you may be struggling with Generalized\n' +
    'Anxiety Disorder. Cognitive-Behavioral Therapy (CBT) and ACT (Acceptance &amp;\n' +
    'Commitment Therapy) are the best treatment options.',
  symptoms: [
    'Do you excessively worry about “big” things like, your job, family, your health, or your finances?',

    'Do you worry about “small” everyday situations like household chores, errands, or\n' +
      'being late for appointments?',

    'Would you like to stop worrying, but you can’t stop or control it?',

    'Are your worries keeping you awake at night?',

    'Are they negatively affecting your ability to concentrate at your job or everyday\n' +
      'tasks?',

    'Do you get really anxious because you are not sure if you’ll be able to get through\n' +
      'the day because of all your worries?',

    'Do you believe that your concerns must be resolved right away?',

    'Are all these excessive worries having a negative effect on your relationships,\n' +
      'your job, school and overall life function?',

    'Do you feel irritable, restless, fatigued, experience muscle tension or have\n' +
      'difficulty falling asleep or staying asleep?',
  ],
  details:
    'Once you are in treatment, you will learn the correct skills to help you look at your\n' +
    'thoughts and feelings with a different set of eyes. You will learn to watch your thinking.\n' +
    'You will realize that you actually have choices regarding your thoughts and feelings. You\n' +
    'will undergo mindfulness training and learn to accept uncertainty. You will learn to\n' +
    'become aware of your internal experiences and know how to respond in a flexible\n' +
    'manner. You will learn to focus on what matters most to you and do what it takes to live\n' +
    'a value-focused life.<br><br>Anxiety does not need to get in the way of your living the life you want to live!',
};

export default generalAnxiety;
