import _ from 'lodash';
import React from 'react';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import BookTeaser from '../../../BookTeaser';
import data from '../../../../data/generalAnxiety';

import styles from './generalAnxiety.module.scss';

const GeneralAnxiety = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498616697/anxiety.jpg';
  const image1 =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498617349/anxietyQuote.jpg';

  const symptoms = _.map(data.symptoms, (symptom, index) => {
    return <li key={index}>{symptom}</li>;
  });

  return (
    <main>
      <PageHeader
        pageCategory="Specialties"
        pageName="Generalized Anxiety Disorder"
        headerImage={background}
      />

      <div className={styles.bookWrapper}>
        <BookTeaser bookName="Let Go of Anxiety" />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.header}>
          During hard times we all worry, but the question is how much is too much?
        </h2>
        <ul>{symptoms}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.overview }} />
      </div>

      <QuoteImage image={image1} maxWidth={510} quote={data.quote1} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.details }} />
      </div>
    </main>
  );
};

export default GeneralAnxiety;
